// AuthMiddleware.tsx
import moment from 'moment';
import React from 'react';
import { Route, Redirect, RouteComponentProps } from 'react-router-dom';

// Define the props for AuthMiddleware
interface AuthMiddlewareProps extends RouteComponentProps {
    component: React.ComponentType<any>;
}

// Define the state for AuthMiddleware
interface AuthMiddlewareState {
    isAuthenticated: boolean;
    loading: boolean;
}

class AuthMiddleware extends React.Component<AuthMiddlewareProps, AuthMiddlewareState> {
    dayOfWeek = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
    constructor(props: AuthMiddlewareProps) {
        super(props);
        this.state = {
            isAuthenticated: false,
            loading: true,
        };

        this.checkAuth = this.checkAuth.bind(this);
    }

    componentDidMount() {
        // Simulate an API call to check authentication
        this.checkAuth();
    }

    checkAuth(){
        let isAuthenticated = false;
        let url = new URL(window.location.href);
        let token = url.searchParams.get("t");
        if (token) {
            const parsed = token.match(/.{1,2}/g);
            if (parsed.length === 3) {
                const utcDate = moment.utc();
                const currentDayIndex = utcDate.day();
                const currentDayAbbr = this.dayOfWeek[currentDayIndex];
                if (currentDayAbbr === parsed[0]) {
                    if (utcDate.month() + 1 === parseInt(parsed[1])) {
                        if (utcDate.date() === parseInt(parsed[2])) {                        
                            isAuthenticated = true;
                            localStorage.setItem('hatchet-t', JSON.stringify(token));
                        }
                    }
                }                
            }
        }
        else {
            if (localStorage.getItem('hatchet-t')) {
                token = JSON.parse(localStorage.getItem('hatchet-t') || '');
                if (token) {
                    const parsed = token.match(/.{1,2}/g);
                    if (parsed.length === 3) {
                        const utcDate = moment.utc();
                        const currentDayIndex = utcDate.day();
                        const currentDayAbbr = this.dayOfWeek[currentDayIndex];
                        if (currentDayAbbr === parsed[0]) {
                            if (utcDate.month() + 1 === parseInt(parsed[1])) {
                                if (utcDate.date() === parseInt(parsed[2])) {                        
                                    isAuthenticated = true;
                                    localStorage.setItem('hatchet-t', JSON.stringify(token));
                                }
                            }
                        }                
                    }
                }
            }
        }
        this.setState({ isAuthenticated, loading: false });
        // Simulate delay
        //await new Promise(resolve => setTimeout(resolve, 1000));
        // Simulate user is authenticated (change this logic as needed)
        
    };

    render() {
        const { component: Component } = this.props;
        const { isAuthenticated, loading } = this.state;

        if (loading) {
            return <div>Loading...</div>; // Show a loading message while checking auth
        }

        return (
            <Route
                render={props =>
                    isAuthenticated ? (
                        <Component {...props} />
                    ) : (
                        <Redirect to="/" /> // Redirect to HomePage if not authenticated
                    )
                }
            />
        );
    }
}

export default AuthMiddleware;
