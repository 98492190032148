import React from "react";
import { connect } from "react-redux";

import LeftPanel from "../common/leftPanel";
import styles from './autoLabelingMetricsPage.module.css';
import { IApplicationState, IMetricsState } from "../../../../../models/applicationState";
import IMetricsActions, * as metricActions from "../../../../../redux/actions/metricActions";
import { RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";



interface IAutoLabelingMetricsState {
    searchText: string;
    processor: string;
    clientId: string;
    startDate: Date | null;
    endDate: Date | null;
    guidKey: string;
    page: number;
    totalPages: number;
}
interface IAutoLabelingMetricsProps  extends RouteComponentProps, React.Props<AutoLabelingMetricsPage> {
    metricActions: IMetricsActions,
    metricState: IMetricsState
}

function mapStateToProps(state: IApplicationState) {
    return {
        metricState: state.metricState
    }
}

function mapDispatchToProps(dispatch) {
    return {
        metricActions: bindActionCreators(metricActions, dispatch)
    }
}

@connect(mapStateToProps, mapDispatchToProps)
export default class AutoLabelingMetricsPage extends React.Component<IAutoLabelingMetricsProps, IAutoLabelingMetricsState> {
    itemsPerPage: number = 15;
    token = '';

    constructor(props: IAutoLabelingMetricsProps) {
        super(props);

        this.state = {
            searchText: "",
            processor: "",
            clientId: "",
            startDate: null,
            endDate: null, 
            guidKey: this.generateGUID(),
            page: 1,
            totalPages: 0
        }

        this.onProcessorChange = this.onProcessorChange.bind(this);
        this.onClientChange = this.onClientChange.bind(this);
        this.onSearchTextChange = this.onSearchTextChange.bind(this);
        this.onSearch = this.onSearch.bind(this);
        this.gotoPage = this.gotoPage.bind(this);
        this.renderPageNumbers = this.renderPageNumbers.bind(this);
        this.onClear = this.onClear.bind(this);
    }

    componentDidMount(): void {
        let url = new URL(window.location.href);        
        this.token = url.searchParams.get("t");
        if (!this.token) {
            this.token = JSON.parse(localStorage.getItem('hatchet-t')) || '';
        }
        this.props.metricActions.getAutoLabelingMetrics(1, this.itemsPerPage, "", "", null, null, this.token);
        this.props.metricActions.getAutoLabelModelMetricsOptions();
          
    }

    componentDidUpdate(prevProps: Readonly<IAutoLabelingMetricsProps>, prevState: Readonly<IAutoLabelingMetricsState>, snapshot?: any): void {
        if (prevProps.metricState && this.props.metricState && JSON.stringify(prevProps.metricState.autoLabelingMetrics) !== JSON.stringify(this.props.metricState.autoLabelingMetrics)) {
            const total = this.props.metricState.totalAutoLabelingMetrics;
            let totalPages = Math.ceil(total / this.itemsPerPage);
            this.setState({ guidKey: this.generateGUID(), totalPages: totalPages });
        }
    }

    generateGUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    onProcessorChange(event) {  
        this.setState({ processor: event.target.value });
    }

    onClientChange(event) {
        this.setState({ clientId: event.target.value });
    }

    onSearchTextChange(event) {
        this.setState({ searchText: event.target.value });
    }

    onSearch() {
        this.props.metricActions.getAutoLabelingMetrics(1, this.itemsPerPage, this.state.processor, this.state.clientId, this.state.startDate, this.state.endDate, this.token);
    }

    onClear() {
        this.setState({ 
            searchText: "", processor: "", clientId: "", startDate: null, endDate: null 
        }, () => {
            this.props.metricActions.getAutoLabelingMetrics(1, this.itemsPerPage, "", "", null, null, this.token);
        });
    }

    gotoPage(page: number) {
        this.setState({ page: page });
        this.props.metricActions.getAutoLabelingMetrics(page, this.itemsPerPage, this.state.processor, this.state.clientId, this.state.startDate, this.state.endDate, this.token);
    }

    renderPageNumbers() {
        if (this.props.metricState) {
            const total = this.props.metricState.totalAutoLabelingMetrics;        
            let totalPages = Math.ceil(total / this.itemsPerPage);
            const { page } = this.state;
            let pages = [];
        
            // Show always the first page
            if (totalPages <= 7) {
                // Less than 7 pages, show all
                for (let i = 1; i <= totalPages; i++) {
                    pages.push(
                        <li key={i} className={`page-item-custom ${page === i ? 'active' : ''}`}>
                            <a
                                className={`page-link-custom ${page === i ? 'page-link-disabled' : ''}`}
                                href="#"
                                onClick={page === i ? (e) => e.preventDefault() : () => this.gotoPage(i)}
                            >
                                {i}
                            </a>
                        </li>
                    );
                }
            } else {
                // More than 7 pages, show first few, last few, and current surrounding pages
                pages.push(
                    <li key={1} className={`page-item-custom ${page === 1 ? 'active' : ''}`}>
                        <a
                            className={`page-link-custom ${page === 1 ? 'page-link-disabled' : ''}`}
                            href="#"
                            onClick={page === 1 ? (e) => e.preventDefault() : () => this.gotoPage(1)}
                        >
                            1
                        </a>
                    </li>
                );
        
                if (page > 4) {
                    pages.push(<li key="ellipsis1" className="page-link-custom">...</li>);
                }
        
                const startPage = Math.max(2, page - 2);
                const endPage = Math.min(totalPages - 1, page + 2);
        
                for (let i = startPage; i <= endPage; i++) {
                    pages.push(
                        <li key={i} className={`page-item-custom ${page === i ? 'active' : ''}`}>
                            <a
                                className={`page-link-custom ${page === i ? 'page-link-disabled' : ''}`}
                                href="#"
                                onClick={page === i ? (e) => e.preventDefault() : () => this.gotoPage(i)}
                            >
                                {i}
                            </a>
                        </li>
                    );
                }
        
                if (page < totalPages - 3) {
                    pages.push(<li key="ellipsis2" className="page-link-custom">...</li>);
                }
        
                pages.push(
                    <li key={totalPages} className={`page-item-custom ${page === totalPages ? 'active' : ''}`}>
                        <a
                            className={`page-link-custom ${page === totalPages ? 'page-link-disabled' : ''}`}
                            href="#"
                            onClick={page === totalPages ? (e) => e.preventDefault() : () => this.gotoPage(totalPages)}
                        >
                            {totalPages}
                        </a>
                    </li>
                );
            }
        
            return pages;
        }
        return null;
    }

    render() {
        let totalItems = 0;
        if (this.props?.metricState?.totalAutoLabelingMetrics) {
            totalItems = this.props.metricState.totalAutoLabelingMetrics;
        }
        
        return (
            <React.Fragment>
                <style>
                {`
                    .pagination-container-custom {
                        display: flex;
                        justify-content: center;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }

                    .pagination-custom {
                        display: flex;
                        list-style: none;
                        padding: 0;
                    }

                    .page-item-custom {
                        margin: 0 2px;
                        background-color: grey !important; /* Set background color to grey */
                        border-radius: 0.25rem;
                    }

                    .page-link-custom {
                        display: block;
                        padding: 0.5rem 0.75rem;
                        color: #007bff;
                        text-decoration: none;
                        background-color: #babcbf !important;
                        border: 1px solid #dee2e6;
                        border-radius: 0.25rem;
                    }

                    .page-link-custom:hover {
                        color: #0056b3;
                        background-color: #e9ecef !important;
                        border-color: #dee2e6;
                    }

                    .page-link-disabled {
                        pointer-events: none;
                        color: #6c757d;
                        background-color: #e9ecef;
                        border-color: #dee2e6;
                    }
                `}
                </style>
                <div className="container-fluid">
                    <div className="row">
                        <LeftPanel active="autoLabelMetrics" history={this.props.history} />
                        <main role="main" className={`col-md-9 ml-sm-auto col-lg-10 px-4 ${styles.mainFullHeight}`}>
                            <h2>Auto Labeling Metrics</h2>
                            <div className={`row ${styles.searchBar}`}>
                                <div className="col-sm-3">
                                    <select className="form-control form-control-sm" placeholder="Select Processor" value={this.state.processor} onChange={this.onProcessorChange}>
                                        <option value="">Select Processor</option>
                                        {this.props?.metricState?.processorOptions && 
                                            this.props.metricState.processorOptions.map((item, index) => {
                                                return <option key={index} value={item}>{item}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="col-sm-3">
                                    <select className="form-control form-control-sm" placeholder="Select Processor" value={this.state.clientId} onChange={this.onClientChange}>
                                        <option value="">Select Client</option>
                                        {this.props?.metricState?.clientOptions && 
                                            this.props.metricState.clientOptions.map((item, index) => {
                                                return <option key={index} value={item}>{item}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className={`col-sm-4 ${styles.dateRangePicker}`}>
                                    <label className="form-control-sm" htmlFor="startDate">From</label>
                                    <DatePicker 
                                        id="startDate"
                                        placeholderText="Start Date"
                                        selected={this.state.startDate} 
                                        onChange={date => this.setState({ startDate: date })} 
                                        className='form-control form-control-sm'
                                    />                                        
                                    <label className="form-control-sm" htmlFor="startDate">To</label>
                                    <DatePicker 
                                        id="endDate"
                                        placeholderText="End Date"
                                        selected={this.state.endDate} 
                                        onChange={date => this.setState({ endDate: date })} 
                                        minDate={this.state.startDate}
                                        className='form-control form-control-sm'
                                    />                                    
                                </div>                                
                                <div className="col-sm-2">
                                    <button className="btn btn-primary btn-sm" onClick={this.onSearch} style={{marginRight: '3px'}}>Search</button>
                                    <button className="btn btn-sm" onClick={this.onClear}>Clear</button>
                                </div>
                            </div>
                            <div className={`row ${styles.totalItemsFound}`}>
                                {totalItems} auto labeling(s) found.
                            </div>
                            <div className={`row ${styles.tableContainer}`}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">File Name</th>
                                            <th scope="col">Roll Id</th>
                                            <th scope="col">Auto Label Version</th>
                                            {/* <th scope="col">Operation Id</th> */}
                                            {/* <th scope="col">Operation Status</th> */}
                                            <th scope="col">Processor</th>
                                            <th scope="col">Client</th>
                                            <th scope="col">Date Processed</th>
                                        </tr>
                                    </thead>
                                    <tbody key={this.state.guidKey}>
                                        { this.props.metricState && this.props.metricState.autoLabelingMetrics  &&
                                            (
                                                this.props.metricState.autoLabelingMetrics.map(item => {
                                                    const utcDate = new Date(item.dateCreated);
                                                    const localDate = moment.utc(item.dateCreated).local().format('YYYY-MM-DD');
                                                    const localDateOnly = utcDate.toLocaleDateString();
                                                    return (
                                                        <tr key={item.documentServiceId}>
                                                            <td scope="row">{item.fileName}</td>
                                                            <td>{item.rollId}</td>
                                                            <td>{item.autoLabelVersion}</td>
                                                            {/* <td>{item.operationId}</td> */}
                                                            <td>{item.processor}</td>
                                                            <td>{item.clientId}</td>
                                                            <td>{localDate}</td>
                                                        </tr>
                                                    )
                                                })                                
                                            )
                                        }
                                    </tbody>
                                </table>
                                <div className="container-fluid">
                                    <div className="pagination-container-custom">
                                        <nav aria-label="Page navigation">
                                            <ul className="pagination-custom">
                                                <li className="page-item-custom">
                                                    <a className={`page-link-custom ${this.state.page <= 1 ? 'page-link-disabled' : ''}`} href="#" aria-label="Previous" onClick={() => this.gotoPage(this.state.page - 1)}>
                                                        <span aria-hidden="true">&laquo;</span>
                                                    </a>
                                                </li>
                                                {this.renderPageNumbers()}                                                
                                                <li className="page-item-custom">
                                                    <a className={`page-link-custom ${this.state.page >= this.state.totalPages ? 'page-link-disabled' : ''}`} href="#" aria-label="Next" onClick={() => this.gotoPage(this.state.page + 1)} >
                                                        <span aria-hidden="true">&raquo;</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </div>   
                        </main>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}