import React from "react";
import { connect } from "react-redux";

import LeftPanel from "../common/leftPanel";
import styles from './formRecognizerModelsPage.module.css';
import { IApplicationState, IFormRecognizerModel, IMetricsState } from "../../../../../models/applicationState";
import IMetricsActions, * as metricActions from "../../../../../redux/actions/metricActions";
import { RouteComponentProps } from "react-router-dom";
import { bindActionCreators } from "redux";

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from "moment";



interface IFormRecognizerModelsState {
    deleteModalId: string;  
    showDeleteModal: boolean;  
    startDate: Date | null;
    endDate: Date | null;
    frModels: IFormRecognizerModel[];
    isLoaded: boolean;
    searchText: string;
    deletedModelIds: string[];
}

interface IFormRecognizerModelsProps  extends RouteComponentProps, React.Props<FormRecognizerModelsPage> {
    metricActions: IMetricsActions,
    metricState: IMetricsState
}

function mapStateToProps(state: IApplicationState) {
    return {
        metricState: state.metricState
    }
}

function mapDispatchToProps(dispatch) {
    return {
        metricActions: bindActionCreators(metricActions, dispatch)
    }
}

@connect(mapStateToProps, mapDispatchToProps)
export default class FormRecognizerModelsPage extends React.Component<IFormRecognizerModelsProps, IFormRecognizerModelsState> {
    itemsPerPage: number = 15;
    token = '';

    constructor(props: IFormRecognizerModelsProps) {
        super(props);

        this.state = {
            deleteModalId: '',
            showDeleteModal: false,
            startDate: null,
            endDate: null, 
            frModels: [],
            isLoaded: false,
            searchText: '',
            deletedModelIds: []
        }

        this.onSearch = this.onSearch.bind(this);
        this.onDelete = this.onDelete.bind(this);
        // this.gotoPage = this.gotoPage.bind(this);
        // this.renderPageNumbers = this.renderPageNumbers.bind(this);
        this.onClear = this.onClear.bind(this);

        this.onShowDeleteModal = this.onShowDeleteModal.bind(this);
        this.onCloseDeleteModal = this.onCloseDeleteModal.bind(this);
        this.filterFrModels = this.filterFrModels.bind(this);
    }

    componentDidMount(): void {
        let url = new URL(window.location.href);        
        this.token = url.searchParams.get("t");
        if (!this.token) {
            this.token = JSON.parse(localStorage.getItem('hatchet-t')) || '';
        }
        this.props.metricActions.getFormRecognizerModels(this.token);        
    }

    componentDidUpdate(prevProps: Readonly<IFormRecognizerModelsProps>, prevState: Readonly<IFormRecognizerModelsState>, snapshot?: any): void {
        if (this.props?.metricState?.formRecognizerModels && this.props?.metricState?.formRecognizerModels?.length > 0 && !this.state.isLoaded) {
            this.setState({
                frModels: this.props.metricState.formRecognizerModels,
                isLoaded: true
            });
        }
    }

    generateGUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            const r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    onSearch() {
        this.props.metricActions.getFormRecognizerModels(this.token);
    }

    onDelete() {
        let { deleteModalId, frModels, deletedModelIds } = this.state;
        let result = this.props.metricActions.deleteFormRecognizerModel(deleteModalId);
        if (result) {
            frModels = frModels.filter(item => item.modelId !== deleteModalId);
            deletedModelIds.push(deleteModalId);
            this.setState({
                deleteModalId: '',
                showDeleteModal: false,
                frModels,
                deletedModelIds
            });
        }                
    }

    onClear() {
        const self = this;
        this.setState({ 
            startDate: null, endDate: null, searchText: '', 
            frModels: this.props.metricState.formRecognizerModels.filter(item => !self.state.deletedModelIds.includes(item.modelId))
        });
    }

    onShowDeleteModal(modelId: string) {
        this.setState({
            deleteModalId: modelId,
            showDeleteModal: true
        });
    }

    onCloseDeleteModal() {
        this.setState({
            deleteModalId: '',
            showDeleteModal: false
        });
    }

    filterFrModels() {
        let { frModels, startDate, endDate, searchText } = this.state;
        if (startDate && endDate) {
            frModels = frModels.filter(item => {
                const localDate = moment.utc(item.createdDateTime).local().format('YYYY-MM-DD');
                return new Date(localDate) >= startDate && new Date(localDate) <= endDate;
            });                        
        }
        if (searchText) {
            frModels = frModels.filter(item => item.modelId.toLowerCase().includes(searchText.toLowerCase()));            
        }
        this.setState({
            frModels
        });
    }

    onSearchTextChange = (e) => {
        const searchText = e.target.value;
        this.setState({ searchText });        
    }

    render() {
        let { deleteModalId, showDeleteModal, startDate, endDate, frModels } = this.state;
        //let frModels = this.props.metricState && this.props.metricState.formRecognizerModels ? this.props.metricState.formRecognizerModels : [];
        let modalStyle = {};
        if (showDeleteModal) {
            modalStyle = {
                display: 'block',
                paddingRight: '17px'
            }
        }

        let totalItems = 0;
        if (this.props?.metricState?.formRecognizerModels?.length > 0) {
            totalItems = this.props.metricState.formRecognizerModels.length;
        }

        frModels = frModels.sort((a, b) => {
            return a.modelId > b.modelId ? 1 : -1;
        });

        return (
            <React.Fragment>
                <style>
                {`
                    .pagination-container-custom {
                        display: flex;
                        justify-content: center;
                        margin-top: 20px;
                        margin-bottom: 20px;
                    }

                    .pagination-custom {
                        display: flex;
                        list-style: none;
                        padding: 0;
                    }

                    .page-item-custom {
                        margin: 0 2px;
                        background-color: grey !important; /* Set background color to grey */
                        border-radius: 0.25rem;
                    }

                    .page-link-custom {
                        display: block;
                        padding: 0.5rem 0.75rem;
                        color: #007bff;
                        text-decoration: none;
                        background-color: #babcbf !important;
                        border: 1px solid #dee2e6;
                        border-radius: 0.25rem;
                    }

                    .page-link-custom:hover {
                        color: #0056b3;
                        background-color: #e9ecef !important;
                        border-color: #dee2e6;
                    }

                    .page-link-disabled {
                        pointer-events: none;
                        color: #6c757d;
                        background-color: #e9ecef;
                        border-color: #dee2e6;
                    }
                `}
                </style>
                <div className="container-fluid">
                    <div className="row">
                        <LeftPanel active="formRecognizerModels" history={this.props.history} />
                        <main role="main" className={`col-md-9 ml-sm-auto col-lg-10 px-4 ${styles.mainFullHeight}`}>
                            <h2>Auto Labeling Metrics</h2>        
                            <div className={`row ${styles.searchBar}`}>
                                <div className="col-sm-4">
                                    <input
                                        type="text"
                                        className='form-control form-control-sm'
                                        placeholder="Search Model Id"
                                        name="searchText"
                                        value={this.state.searchText}
                                        onChange={this.onSearchTextChange}
                                    />
                                </div>
                                <div className={`col-sm-4 ${styles.dateRangePicker}`}>
                                    <label className="form-control-sm" htmlFor="startDate">From</label>
                                    <DatePicker 
                                        id="startDate"
                                        placeholderText="Start Date"
                                        selected={this.state.startDate} 
                                        onChange={date => this.setState({ startDate: date })} 
                                        className='form-control form-control-sm'
                                    />                                        
                                    <label className="form-control-sm" htmlFor="startDate">To</label>
                                    <DatePicker 
                                        id="endDate"
                                        placeholderText="End Date"
                                        selected={this.state.endDate} 
                                        onChange={date => this.setState({ endDate: date })} 
                                        minDate={this.state.startDate}
                                        className='form-control form-control-sm'
                                    />                                    
                                </div>                                
                                <div className="col-sm-4">
                                    <button className="btn btn-primary btn-sm" onClick={this.filterFrModels} style={{marginRight: '3px'}}>Search</button>
                                    <button className="btn btn-sm" onClick={this.onClear}>Clear</button>
                                </div>
                            </div>                    
                            <div className={`row ${styles.totalItemsFound}`}>
                                {totalItems} model(s) found.
                            </div>
                            <div className={`row ${styles.tableContainer}`}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Model Id</th>
                                            <th scope="col">API Version</th>
                                            <th scope="col">Created Date</th>
                                            <th scope="col">Expiration Date</th>
                                            <th scope="col"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { frModels  &&
                                            (
                                                frModels.map(item => {
                                                    const hideDelete = item.modelId.startsWith('prebuilt-');
                                                    let deleteBtnStyle = {};
                                                    if (hideDelete || item.isActive) {
                                                        deleteBtnStyle = {
                                                            display: 'none'
                                                        }
                                                    }
                                                    const utcDate = new Date(item.createdDateTime);
                                                    const localDate = moment.utc(item.createdDateTime).local().format('YYYY-MM-DD');
                                                    const localExpDate = moment.utc(item.expirationDateTime).local().format('YYYY-MM-DD');
                                                    return (
                                                        <tr key={item.modelId}>
                                                            <td scope="row">{item.modelId}</td>
                                                            <td>{item.apiVersion}</td>
                                                            <td>{localDate}</td>
                                                            <td>{localExpDate}</td>
                                                            <td>
                                                                { hideDelete && <span className="badge badge-secondary">Prebuilt</span> }
                                                                { item.isActive && <span className="badge badge-success">Active</span> }
                                                                <button className="btn btn-primary btn-sm" onClick={(e) => this.onShowDeleteModal(item.modelId)} style={deleteBtnStyle}>
                                                                    Delete
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })                                
                                            )
                                        }
                                    </tbody>
                                </table>
                                {/* <div className="container-fluid">
                                    <div className="pagination-container-custom">
                                        <nav aria-label="Page navigation">
                                            <ul className="pagination-custom">
                                                <li className="page-item-custom">
                                                    <a className={`page-link-custom ${this.state.page <= 1 ? 'page-link-disabled' : ''}`} href="#" aria-label="Previous" onClick={() => this.gotoPage(this.state.page - 1)}>
                                                        <span aria-hidden="true">&laquo;</span>
                                                    </a>
                                                </li>
                                                {this.renderPageNumbers()}                                                
                                                <li className="page-item-custom">
                                                    <a className={`page-link-custom ${this.state.page >= this.state.totalPages ? 'page-link-disabled' : ''}`} href="#" aria-label="Next" onClick={() => this.gotoPage(this.state.page + 1)} >
                                                        <span aria-hidden="true">&raquo;</span>
                                                    </a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div> */}
                            </div>   
                        </main>
                    </div>
                </div>
                <div className="modal fade show" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true" style={modalStyle}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">Modal title</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure you want to delete model with id: {deleteModalId}?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.onCloseDeleteModal}>No</button>
                                <button type="button" className="btn btn-primary" onClick={this.onDelete}>Yes</button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}