import React, { ReactElement, RefObject } from "react";
import { IAutoLabelModel, IGetDocumentServiceModel } from "../../../../../models/applicationState";
import { toast } from "react-toastify";

interface IGenerateModelProps extends React.Props<GenerateModelModal> {
    showGenerateModal: boolean,
    selectAutoLabel: IAutoLabelModel,
    documentServices: IGetDocumentServiceModel[],
    selectedDocumentServiceId: string,
    toggleGenerateModal(show: boolean),
    //onGenerateModel(documentServiceId: string): Promise<boolean>,    
    onSelectDocumentServiceChange(id: string),
    search(): Promise<void>,
    getAutoLabelVersions(),
    selectAutoLabelChange(name: string, value: string),
    saveAutoLabel(),
    resetAutoLabel(): Promise<boolean>
}

interface IGenerateModelState {
    editVersionLimit: boolean,
    resetModalShow: boolean
}

export default class GenerateModelModal extends React.Component<IGenerateModelProps, IGenerateModelState> {

    constructor(props) {
        super(props);

        this.state = {
            editVersionLimit: false,
            resetModalShow: false
        }

        //this.onGenerateModal = this.onGenerateModal.bind(this);
        this.onEditVersionClicked = this.onEditVersionClicked.bind(this);
        this.onSelectAutoLabelChange = this.onSelectAutoLabelChange.bind(this);
        this.openResetAutoLabelModal = this.openResetAutoLabelModal.bind(this);
        this.closeResetAutoLabelModal = this.closeResetAutoLabelModal.bind(this);
        this.onResetAutoLabelModal = this.onResetAutoLabelModal.bind(this);
    }

    // async onGenerateModal() {
    //     const result = this.props.onGenerateModel(this.props.selectedDocumentServiceId);
    //     if (result) {
    //         toast.info('Please wait a few minutes and then refresh your browser, generating auto label model is in progress.', { autoClose: 5000 });
    //         await this.props.search();
    //     }
    //     else {
    //         toast.error('An error occured while processing your request.', { autoClose: 5000 });
    //     }
    // }

    async onEditVersionClicked() {
        const self = this;
        let { editVersionLimit } = this.state;
        if (editVersionLimit) {
            await this.props.saveAutoLabel();            
        }
        this.setState({
            editVersionLimit: !editVersionLimit
        }, () => {
            self.inputMaxLimitRef.current.focus()
        });
    }

    onSelectAutoLabelChange(e) {
        const { name, value } = e.target;
        this.props.selectAutoLabelChange(name, value);        
    }

    openResetAutoLabelModal() {
        this.setState({
            resetModalShow: true
        });
    }

    closeResetAutoLabelModal() {
        this.setState({
            resetModalShow: false
        });
    }

    onResetAutoLabelModal() {
        const result = this.props.resetAutoLabel();
        if (result) {
            this.closeResetAutoLabelModal();
        }
    }

    private inputMaxLimitRef = React.createRef<HTMLInputElement>();

    render() {
        const { editVersionLimit, resetModalShow } = this.state;
        const { showGenerateModal, selectAutoLabel, selectedDocumentServiceId, documentServices } = this.props;
        let generateModalStyle = showGenerateModal ? {paddingRight: '17px', display: 'block'} : {};

        let selectedJurisdiction = '';
        if (selectAutoLabel.countryCode && selectAutoLabel.municipality && selectAutoLabel.provinceCode) {
            selectedJurisdiction = `${selectAutoLabel.municipality}-${selectAutoLabel.provinceCode}-${selectAutoLabel.countryCode}`;
        }

        const isRunning = documentServices.some(r => r.operationStatus === "running");

        let resetModalClass = "modal fade";
        let resetModalStyle = {};
        if (resetModalShow) {
            resetModalClass = "modal fade show";
            resetModalStyle = {
                display: 'block',
                paddingRight: '15%',
                paddingLeft: '25%'
            }
        }

        return (
            <React.Fragment>
                <style>
                {`
                    .modal-dialog {
                        max-width: 750px !important;
                        width: 750px !important;
                    }
                    .modal-content {
                        max-width: 750px !important;
                        width: 750px !important;
                    }
                `}
                </style>
                <div className={showGenerateModal ? "modal fade show" : "modal fade"} id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" style={generateModalStyle}>
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header" style={{backgroundColor: '#0f467e'}}>
                                <h5 className="modal-title" id="exampleModalLabel">
                                    Generate Autolabel Model                                    
                                </h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => this.props.toggleGenerateModal(false)}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">                            
                                <form>
                                    <div className="form-group row h-100 justify-content-center align-items-center">
                                        <label htmlFor="inputModelId" className="col-sm-2 col-form-label">Model Id</label>
                                        <div className="col-sm-10">
                                            <input type="text" readOnly className="form-control" id="inputModelId" value={selectAutoLabel.modelId} />
                                        </div>
                                    </div>
                                    {/* <div className="form-group row h-100 justify-content-center align-items-center">
                                        <label htmlFor="inputModelName" className="col-sm-2 col-form-label">Model Name</label>
                                        <div className="col-sm-10">
                                            <input type="text" className="form-control" id="inputModelName" value={selectAutoLabel.modelName} />
                                        </div>
                                    </div> */}
                                    <div className="form-group row h-100 justify-content-center align-items-center">
                                        <label htmlFor="inputDoctype" className="col-sm-2 col-form-label">Document Type</label>
                                        <div className="col-sm-10">
                                            <input type="text" readOnly className="form-control" id="inputDoctype" value={selectAutoLabel.documentType} />
                                        </div>
                                    </div>
                                    <div className="form-group row h-100 justify-content-center align-items-center">
                                        <label htmlFor="inputJurisdiction" className="col-sm-2 col-form-label">Jurisdiction</label>
                                        <div className="col-sm-10">
                                            <input type="text" readOnly className="form-control" id="inputJurisdiction" value={selectedJurisdiction} />
                                        </div>
                                    </div>
                                    <div className="form-group row h-100 justify-content-center align-items-center">
                                        <label htmlFor="inputCurrentModel" className="col-sm-2 col-form-label">Current Model Count</label>
                                        <div className="col-sm-10">
                                            <input type="text" readOnly className="form-control" id="inputCurrentModel" value={selectAutoLabel.modelCount} />
                                        </div>
                                    </div>
                                    <div className="form-group row h-100 justify-content-center align-items-center">
                                        <label htmlFor="inputJurisdiction" className="col-sm-2 col-form-label">Max Version Limit</label>
                                        <div className="col-sm-8">
                                            <input type="text" name="maxVersionLimit" readOnly={!editVersionLimit} className="form-control" id="inputMaxVersionLimit" value={selectAutoLabel.maxVersionLimit} ref={this.inputMaxLimitRef} onChange={this.onSelectAutoLabelChange} />                                            
                                        </div>
                                        <div className="col-sm-2">
                                            <button type="button" className="btn btn-primary btn-sm" style={{width: '100%'}} onClick={this.onEditVersionClicked}>{editVersionLimit ? 'Update' : 'Edit'}</button>
                                        </div>
                                    </div>
                                    <fieldset className="form-group row">
                                        <legend className="col-form-label col-sm-2 float-sm-left pt-0">Attachments</legend>
                                        <div className="col-sm-10" style={{maxHeight: '200px', overflow: 'scroll'}}>
                                            <table className="table table-bordered" style={{backgroundColor: '#4691db'}}>
                                                <thead>
                                                    <tr>
                                                        <th>Document Service Id</th>
                                                        <th>Version</th>
                                                        <th>Operation Status</th>
                                                        <th>Active</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    { this.props.documentServices && 
                                                        this.props.documentServices.map(docService => {
                                                            const active = docService.active ? 'Yes' : 'No';
                                                            return (
                                                                <tr>
                                                                    <td>
                                                                        {docService.documentServiceId}
                                                                        {/* <div className="form-check">
                                                                            <input className="form-check-input" type="radio" name="gridRadios" id={`gridRadios-${docService.documentServiceId}`} value={docService.documentServiceId} checked={selectedDocumentServiceId == docService.documentServiceId} onChange={() => this.props.onSelectDocumentServiceChange(docService.documentServiceId)} />
                                                                            <label className="form-check-label" htmlFor="gridRadios3">
                                                                                {docService.documentServiceId}
                                                                            </label>
                                                                        </div> */}
                                                                    </td>
                                                                    <td>
                                                                        {docService.version}
                                                                    </td>
                                                                    <td>
                                                                        {docService.operationStatus}
                                                                    </td>
                                                                    <td>
                                                                        {active}
                                                                    </td>
                                                                </tr>                
                                                            )
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={() => this.props.getAutoLabelVersions()}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-arrow-clockwise" viewBox="0 0 16 16" style={{paddingRight: '2px'}}>
                                        <path fill-rule="evenodd" d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2z"></path>
                                        <path d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466"></path>
                                    </svg>
                                    Refresh
                                </button>
                                <button type="button" className="btn btn-secondary" onClick={() => this.openResetAutoLabelModal()}>Reset Model</button>
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={() => this.props.toggleGenerateModal(false)}>Close</button>
                                {/* <button type="button" className="btn btn-primary" onClick={() => this.onGenerateModal()} disabled={isRunning}>Generate Model</button> */}
                                
                            </div>
                        </div>
                    </div>

                    {/* Model Reset Modal */}
                    <div className={resetModalClass} id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true" style={resetModalStyle}>
                        <div className="modal-dialog modal-dialog-centered modal-sm">
                            <div className="modal-content" style={{backgroundColor: '#0f467e', width: '75%'}}>
                            <div className="modal-header">
                                <h5 className="modal-title" id="staticBackdropLabel">Reset Model</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={this.closeResetAutoLabelModal}>
                                <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body">
                                Are you sure you want to reset auto label model?
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={this.closeResetAutoLabelModal}>No</button>
                                <button type="button" className="btn btn-primary" onClick={this.onResetAutoLabelModal}>Yes</button>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>   
            </React.Fragment>
            
        )
    }
}