
import axios from 'axios';
import { IAutoLabelModel, IGetAutoLabelResult, IGetDocumentServiceModel } from "../models/applicationState";

export interface IAutoLabelModelManagementService {
    get(searchText: string): Promise<IGetAutoLabelResult>;
    getById(modelId: string): Promise<IAutoLabelModel>;
    getDocumentServicesByModelId(modelId: string): Promise<IGetDocumentServiceModel[]>;
    //generateModel(documentSeviceId: string): Promise<boolean>;
    save(autoLabelModel: IAutoLabelModel): Promise<boolean>;
    resetAutoLabelModel(modelId: string): Promise<boolean>;
}

export default class AutoLabelModelManagementService implements IAutoLabelModelManagementService {    
    public async get(searchText: string = ''): Promise<IGetAutoLabelResult> {
        return new Promise<IGetAutoLabelResult>(async (resolve, reject) => {
            try {
                const autoLabelModels = await axios.get<IGetAutoLabelResult>(`${process.env.REACT_APP_HACHET_API_BASE_URL}api/ModelSdc/auto-label-models?searchText=${searchText}`);
                if (autoLabelModels) {
                    resolve(autoLabelModels.data);
                }                
                else {
                    reject();
                }
            }
            catch(ex) {
                reject(ex);
            }
        })
    }

    public async getById(modelId: string): Promise<IAutoLabelModel> {
        return new Promise<IAutoLabelModel>(async (resolve, reject) => {
            try {
                const autoLabelModels = await axios.get<IAutoLabelModel>(`${process.env.REACT_APP_HACHET_API_BASE_URL}api/ModelSdc/auto-label-models/${modelId}`);
                if (autoLabelModels) {
                    resolve(autoLabelModels.data);
                }                
                else {
                    reject();
                }
            }
            catch(ex) {
                reject(ex);
            }
        })
    }

    public async getDocumentServicesByModelId(modelId: string): Promise<IGetDocumentServiceModel[]> {
        return new Promise<IGetDocumentServiceModel[]>(async (resolve, reject) => {
            try {
                const docServices = await axios.get<IGetDocumentServiceModel[]>(`${process.env.REACT_APP_HACHET_API_BASE_URL}api/ModelSdc/document-services/${modelId}`);
                if (docServices) {
                    resolve(docServices.data);
                }                
                else {
                    reject([]);
                }
            }
            catch(ex) {
                reject(ex);
            }
        })
    }

    // public async generateModel(documentSeviceId: string): Promise<boolean> {
    //     return new Promise<boolean>(async (resolve, reject) => {
    //         try {
    //             const result = await axios.get<boolean>(parseEndpointByType(`auto-label-model/generate/${documentSeviceId}`));
    //             if (result) {
    //                 resolve(result.data);
    //             }                
    //             else {
    //                 reject([]);
    //             }
    //         }
    //         catch(ex) {
    //             reject(ex);
    //         }
    //     })
    // }

    public async save(autoLabelModel: IAutoLabelModel): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const result = await axios.post<boolean>(`${process.env.REACT_APP_HACHET_API_BASE_URL}api/ModelSdc/auto-label-model`, autoLabelModel);
                if (result) {
                    resolve(result.data);
                }                
                else {
                    reject([]);
                }
            }
            catch(ex) {
                reject(ex);
            }
        })
    }

    public async resetAutoLabelModel(modelId: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const result = await axios.post<boolean>(`${process.env.REACT_APP_HACHET_API_BASE_URL}api/ModelSdc/auto-label-model/${modelId}/reset`);
                if (result) {
                    resolve(result.data);
                }                
                else {
                    reject([]);
                }
            }
            catch(ex) {
                reject(ex);
            }
        })
    }
}