import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { IApplicationState, IAutoLabelModel, IAutoLabelState, IGetDocumentServiceModel } from "../../../../../models/applicationState";
import { bindActionCreators } from "redux";
import IAutoLabelModelManagementActions, * as autoLabelActions from "../../../../../redux/actions/autoLabelModelManagementActions";
import GenerateModelModal from "./generateModelModal";
import { toast } from "react-toastify";
import {Dropdown, IDropdownOption, PrimaryButton, TextField} from '@fluentui/react';

import styles from './autoLabelModelManagement.module.css'; // Import CSS Module
import 'bootstrap/dist/css/bootstrap.min.css'; // Ensure Bootstrap is imported
import LeftPanel from "../common/leftPanel";

interface IAutoLabelModelManagementPageState {
    showGenerateModal: boolean,
    selectAutoLabel: IAutoLabelModel | {}, 
    documentServices: IGetDocumentServiceModel[],
    selectedDocumentServiceId: string,
    sourceOption: string,
    searchText: string
}

interface IAutoLabelModelManagementPageProps extends RouteComponentProps, React.Props<AutoLabelModelManagementPage> {
    autoLabelState: IAutoLabelState,
    autoLabelModelActions: IAutoLabelModelManagementActions
}


function mapStateToProps(state: IApplicationState) {
    return {
        autoLabelState: state.autoLabelState
    }
}

function mapDispatchToProps(dispatch) {
    return {
        autoLabelModelActions: bindActionCreators(autoLabelActions, dispatch)
    }
}

@connect(mapStateToProps, mapDispatchToProps)
export default class AutoLabelModelManagementPage extends React.Component<IAutoLabelModelManagementPageProps, IAutoLabelModelManagementPageState> {
    constructor(props) {
        super(props);

        this.state = {
            showGenerateModal: false, 
            selectAutoLabel: {}, 
            documentServices: [], 
            selectedDocumentServiceId: '',
            sourceOption: "localFile",
            searchText: ''
        }

        this.toggleGenerateModal = this.toggleGenerateModal.bind(this);
        this.selectAutoLabel = this.selectAutoLabel.bind(this);
        //this.onGenerateModel = this.onGenerateModel.bind(this);
        this.onSelectDocumentServiceChange = this.onSelectDocumentServiceChange.bind(this);
        this.getAutoLabelVersions = this.getAutoLabelVersions.bind(this);
        this.selectAutoLabelChange = this.selectAutoLabelChange.bind(this);
        this.saveAutoLabel = this.saveAutoLabel.bind(this);
        this.resetAutoLabel = this.resetAutoLabel.bind(this);
        this.search = this.search.bind(this);
        this.onSearchTextChange = this.onSearchTextChange.bind(this);
        this.clear = this.clear.bind(this);
    }

    async componentDidMount() {
        this.search();
    }

    async search() {
        await this.props.autoLabelModelActions.get(this.state.searchText);
    }

    async clear() {
        this.setState({
            searchText: ''
        });
        await this.props.autoLabelModelActions.get('');
    }

    toggleGenerateModal(show: boolean) {
        const { showGenerateModal } = this.state;
        this.setState({
            showGenerateModal: show
        });
    }

    async selectAutoLabel(modelId: string) {
        const autoLabel = await this.props.autoLabelModelActions.getById(modelId);
        const documentServices = await this.props.autoLabelModelActions.getDocumentServicesByModelId(modelId);
        this.setState({
            selectAutoLabel: autoLabel,
            documentServices: documentServices
        }, () => {
            this.toggleGenerateModal(true);
        });        
    }

    async saveAutoLabel() {
        const { selectAutoLabel } = this.state;
        const saveResult = await this.props.autoLabelModelActions.save(selectAutoLabel as IAutoLabelModel);
        if (saveResult) {
            toast.info('Save successful');
        }        
    }

    async resetAutoLabel(): Promise<boolean> {
        const { selectAutoLabel } = this.state;
        let autoLabelModel = selectAutoLabel as IAutoLabelModel;
        const result = await this.props.autoLabelModelActions.resetAutoLabelModel(autoLabelModel.modelId);
        if (result) {
            toast.info('Reset successful');
            await this.getAutoLabelVersions();
            return true;
        }        
        else {
            toast.error('Failed to reset model');
            return false;
        }        
    }

    selectAutoLabelChange(name: string, value: string) {
        const { selectAutoLabel } = this.state;
        selectAutoLabel[name] = value;
        this.setState({
            selectAutoLabel
        });
    }

    async getAutoLabelVersions() {
        const selectAutoLabel = this.state.selectAutoLabel as IAutoLabelModel;
        if (selectAutoLabel && selectAutoLabel.modelId) {
            const documentServices = await this.props.autoLabelModelActions.getDocumentServicesByModelId(selectAutoLabel.modelId);
            this.setState({
                documentServices: documentServices
            });
        }
    }

    // async onGenerateModel() {
    //     const result = this.props.autoLabelModelActions.generateModel(this.state.selectedDocumentServiceId);
    //     if (result) {
    //         toast.info('Please wait a few minutes and then refresh your browser, generating auto label model is in progress.', { autoClose: 5000 });
    //         await this.props.autoLabelModelActions.get();
    //     }
    //     else {
    //         toast.error('An error occured while processing your request.', { autoClose: 5000 });
    //     }
    // }

    onSelectDocumentServiceChange(id: string) {
        this.setState({
            selectedDocumentServiceId: id
        })
    }

    private onSelectSourceChange = (event, option) => {
        
    }

    onSearchTextChange = (event) => {
        const { value } = event.target;
        this.setState({
            searchText: value
        });
    }

    render() {
        const { showGenerateModal, documentServices, selectedDocumentServiceId, searchText } = this.state;
        const selectAutoLabel = this.state.selectAutoLabel as IAutoLabelModel;
        const sourceOptions: IDropdownOption[] = [
            {key: "localFile", text: 'Local File'},
            {key: "url", text: 'Url'},
        ];
        
        return (
            <React.Fragment>
                <div className="container-fluid">
                    <div className="row">
                        <LeftPanel active="modelManagement" history={this.props.history} />
                        <main role="main" className={`col-md-9 ml-sm-auto col-lg-10 px-4 ${styles.mainFullHeight}`}>
                            <h2>Model Management</h2>
                            <div className="row">
                                <div className="col-sm-3">
                                    <input
                                        type="text"
                                        className={`${styles.searchInput} ${styles.searchInputSmall}`}
                                        placeholder="Search models by jurisdiction..."
                                        name="searchText"
                                        value={searchText}
                                        onChange={this.onSearchTextChange}
                                    />
                                </div>
                                <div className="col-sm-9">
                                    <button className="btn btn-primary btn-sm" onClick={this.search}>Search</button> &nbsp;
                                    <button className="btn btn-sm" onClick={this.clear}>Clear</button>
                                </div>
                            </div>
                            <div className={`row ${styles.tableContainer}`}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">Model Id</th>
                                            <th scope="col">Document Type</th>
                                            <th scope="col">Jurisdiction</th>
                                            {/* <th scope="col">Model Name</th> */}
                                            <th scope="col">
                                                Consumed Model                                                                    
                                            </th>
                                            <th scope="col">Max Model Limit</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { this.props.autoLabelState && this.props.autoLabelState.autoLabelModels && this.props.autoLabelState.autoLabelModels.length > 0  &&
                                            (
                                                this.props.autoLabelState.autoLabelModels.map(item => {
                                                    let jurisdiction = '';
                                                    if (item.countryCode && item.municipality && item.provinceCode) {
                                                        jurisdiction = `${item.municipality}-${item.provinceCode}-${item.countryCode}`;
                                                    }
                                                    return (
                                                        <tr key={item.modelId}>
                                                            <td scope="row">{item.modelId}</td>
                                                            <td>{item.documentType}</td>
                                                            <td>{jurisdiction}</td>
                                                            {/* <td>{item.modelName}</td> */}
                                                            <td scope="row">{item.modelCount}</td>
                                                            <td>{item.maxVersionLimit}</td>
                                                            <td>
                                                                <button className="btn btn-sm btn-primary" onClick={() => this.selectAutoLabel(item.modelId)}>
                                                                    Details
                                                                </button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })                                
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>   
                        </main>
                    </div>
                </div>
                
                <GenerateModelModal 
                    showGenerateModal={showGenerateModal}
                    selectAutoLabel={selectAutoLabel}
                    documentServices={documentServices}
                    selectedDocumentServiceId={selectedDocumentServiceId}
                    toggleGenerateModal={this.toggleGenerateModal}
                    //onGenerateModel={this.props.autoLabelModelActions.generateModel}
                    onSelectDocumentServiceChange={this.onSelectDocumentServiceChange}
                    search={this.search}
                    getAutoLabelVersions={this.getAutoLabelVersions}
                    selectAutoLabelChange={this.selectAutoLabelChange}
                    saveAutoLabel={this.saveAutoLabel}
                    resetAutoLabel={this.resetAutoLabel}
                />
                    
            </React.Fragment>
            
        )
    }
}