import axios from "axios";
import { IAutoLabelModelMetricsOptions, IAutoLabelModelMetricsResultModel, IAutoLabelingMetricsResultModel, IFormRecognizerModel, IFormRecognizerModelList, IOcrMetricsResultModel } from "../models/applicationState";

export interface IMetricsService {
    getAutoLabelModelMetrics(page: number, itemsPerPage: number, processor: string, clientId: string, startDate: Date, endDate: Date, token: string): Promise<IAutoLabelModelMetricsResultModel>;
    getAutoLabelingMetrics(page: number, itemsPerPage: number, processor: string, clientId: string, startDate: Date, endDate: Date, token: string): Promise<IAutoLabelingMetricsResultModel>;    
    getOcrMetrics(page: number, itemsPerPage: number, processor: string, clientId: string, startDate: Date, endDate: Date, token: string): Promise<IOcrMetricsResultModel>;
    getAutoLabelModelMetricsOptions(): Promise<IAutoLabelModelMetricsOptions>;
    getFormRecognizerModels(token: string): Promise<IFormRecognizerModelList>;
    deleteFormRecognizerModel(modelId: string): Promise<boolean>;
}

export default class MetricsService implements IMetricsService {
    public async getAutoLabelModelMetrics(page: number, itemsPerPage: number, processor: string, clientId: string, startDate: Date, endDate: Date, token: string): Promise<IAutoLabelModelMetricsResultModel> {
        return new Promise<IAutoLabelModelMetricsResultModel>(async (resolve, reject) => {
            try {
                const autoLabelModelMetrics = await axios.post<IAutoLabelModelMetricsResultModel>(`${process.env.REACT_APP_HACHET_API_BASE_URL}api/Metrics/auto-label-models`, 
                                                        {
                                                            page, 
                                                            itemsPerPage,
                                                            processor,
                                                            clientId,
                                                            startDate, 
                                                            endDate,
                                                            token
                                                        });
                if (autoLabelModelMetrics) {
                    resolve(autoLabelModelMetrics.data);
                }
                else {
                    reject();
                }
            }
            catch (ex) {
                reject(ex);
            }
        })
    }

    public async getAutoLabelingMetrics(page: number, itemsPerPage: number, processor: string, clientId: string, startDate: Date, endDate: Date, token: string): Promise<IAutoLabelingMetricsResultModel> {
        return new Promise<IAutoLabelingMetricsResultModel>(async (resolve, reject) => {
            try {
                const autoLabelModelMetrics = await axios.post<IAutoLabelingMetricsResultModel>(`${process.env.REACT_APP_HACHET_API_BASE_URL}api/Metrics/auto-labeling`, 
                                                        {
                                                            page, 
                                                            itemsPerPage,
                                                            processor,
                                                            clientId,
                                                            startDate, 
                                                            endDate,
                                                            token
                                                        });
                if (autoLabelModelMetrics) {
                    resolve(autoLabelModelMetrics.data);
                }
                else {
                    reject();
                }
            }
            catch (ex) {
                reject(ex);
            }
        })
    }

    public async getOcrMetrics(page: number, itemsPerPage: number, processor: string, clientId: string, startDate: Date, endDate: Date, token: string): Promise<IOcrMetricsResultModel> {
        return new Promise<IOcrMetricsResultModel>(async (resolve, reject) => {
            try {
                const ocrModelMetrics = await axios.post<IOcrMetricsResultModel>(`${process.env.REACT_APP_HACHET_API_BASE_URL}api/Metrics/ocr`, 
                                                        {
                                                            page, 
                                                            itemsPerPage,
                                                            processor,
                                                            clientId,
                                                            startDate, 
                                                            endDate,
                                                            token
                                                        });
                if (ocrModelMetrics) {
                    resolve(ocrModelMetrics.data);
                }
                else {
                    reject();
                }
            }
            catch (ex) {
                reject(ex);
            }
        })
    }

    public async getAutoLabelModelMetricsOptions(): Promise<IAutoLabelModelMetricsOptions> {
        return new Promise<IAutoLabelModelMetricsOptions>(async (resolve, reject) => {
            try {
                const autoLabelModelMetricsOptions = await axios.get<IAutoLabelModelMetricsOptions>(`${process.env.REACT_APP_HACHET_API_BASE_URL}api/Metrics/metrics-options`);
                if (autoLabelModelMetricsOptions) {
                    resolve(autoLabelModelMetricsOptions.data);
                }
                else {
                    reject();
                }
            }
            catch (ex) {
                reject(ex);
            }
        })
    }

    public async getFormRecognizerModels(token: string): Promise<IFormRecognizerModelList> {
        return new Promise<IFormRecognizerModelList>(async (resolve, reject) => {
            try {
                const formRecognizerList = await axios.get<IFormRecognizerModelList>(`${process.env.REACT_APP_HACHET_API_BASE_URL}api/ModelSdc/form-recognizer-models/${token}`);
                if (formRecognizerList) {
                    resolve(formRecognizerList.data);
                }
                else {
                    reject();
                }
            }
            catch (ex) {
                reject(ex);
            }
        })
    }

    public async deleteFormRecognizerModel(modelId: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const formRecognizerList = await axios.delete<boolean>(`${process.env.REACT_APP_HACHET_API_BASE_URL}api/ModelSdc/form-recognizer-models/${modelId}/delete`);
                if (formRecognizerList) {
                    resolve(formRecognizerList.data);
                }
                else {
                    reject();
                }
            }
            catch (ex) {
                reject(ex);
            }
        })
    }
}