import { IAutoLabelState } from "../../models/applicationState";
import { AnyAction } from "../actions/actionCreators";
import { ActionTypes } from "../actions/actionTypes";


export const reducer = (state: IAutoLabelState = null, action: AnyAction): IAutoLabelState => {    
    switch(action.type) {
        case ActionTypes.GET_AUTOLABELS: {
            const autoLabelModels = action.payload.autoLabelModels;
            return { 
                ...state,
                autoLabelModels
            };
            break;
        }
        default: {
            return state;
        }
    }
}